.horizontalInputWrapper {
    display: flex;
}

.horizontalInputWrapper > * {
    flex-grow: 1;
}

.horizontalInputWrapper div {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid transparent;
}

.descriptionField {
    min-height: 200px;
}

.activeStatusRow {

}

.addressRow {
    width: 20%;
}

.headerInfo {
    background: #fff;
    padding: 10px 32px 0px 32px;
}

.headerWrapper a {
    color: #007eca;
}

.headerWrapper hr {
    margin: 16px -16px 0px -16px;
}

.tabsWrapper {
    background: #fff;
}

.headerTopRow {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.backToSearchLink {
    display: flex;
    align-items: center;
    line-height: initial;
}

.backToSearchLink svg {
    margin-right: 8px;
}

.complexName {
    font-size: 21px;
    font-weight: bold;
    margin-top: 12px;
}

.amenitiesActionBarWrapper {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e5ebed;
    box-shadow: 0px 15px 10px -15px rgba(168, 168, 168, 0.5);
    margin: 0px -16px 20px -16px;
    padding: 15px;
}

.actionBarWrapper {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e5ebed;
    box-shadow: 0 2px 14px 0 rgba(168, 168, 168, 0.5);
    margin: 0 -16px;
}

.flexEnd {
    justify-content: flex-end;


}
.actionBarSearch {
    width: 400px;
}

.actionBarActions {
    display: flex;
    flex-direction: row;
}

.actionBarWrapper button {
    margin-right: 24px;
}

.headerWrapper {
    overflow: hidden;
    margin: 16px -16px 0px -16px;
    border-top: solid 2px #e7e7e7;
}

.complexNotFoundContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
}

.floatingButtonContainer {
    position: fixed;
    bottom: 22px;
    right: 22px;
}

.back-white {
    background-color: white;
}
.warning {
    margin-top: 16px;
    margin-left: 25px;
    width: 550px;
}



.loader-dots {
    padding: 40px 0px;
    display: block;
    margin: auto;
}

.loader-dots > div {
    background-color: #0078AB;
    opacity: 0.2;
    margin: 0 4px;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 0.8s infinite ease-in-out both;
    animation: sk-bouncedelay 0.8s infinite ease-in-out both;
}

.loader-dots .bounce1 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.loader-dots .bounce2 {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0.8) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
          opacity: 1;
      }
}
.messageBox {
  width: 100%;
  background-color: #FFFFFF;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccd6db;
  padding: 15px;
  box-shadow: 2px 2px #e5eaec;
  border-left-width: 5px;
  color: #2C3439;
  display: flex;
  align-items: center;
  float: left;
  font-size: 0.85rem;
}

.error {
  border-left-color: #D62E4F;
}

.info {
  border-left-color: #003349;
}

.warning {
  border-left-color: #DDAD49;
}

.success {
  border-left-color: #6BA342;
}

.alertText {
  padding-left: 0.7rem;
}
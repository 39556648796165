.default {
    padding: 16px 24px;
    font-weight: 600;
    margin: 0 16px;
}

.selected {
    color: #007eca;
    border-bottom: 4px solid #007eca;
}

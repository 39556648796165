.tableContainer {
  border-top: 1px solid #ccd6db;
}

.cardMessage {
  font-weight: unset;
  margin: 0px;
  color: #777;
  text-align: center;
  padding: 40px 0;
}

.topSection {
  padding: 16px 0;
}

.searchAgainContainer {
  cursor: pointer;
}

.searchAgainText {
  height: 24px;
  width: 95px;
  color: #007eca;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 600;
  padding-left: 5px;
}

.unitTotal {
  height: 32px;
  width: 159px;
  color: #2c3439;
  font-family: 'Nunito Sans';
  font-size: 21px;
  line-height: 32px;
}


.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 14px 0 rgba(168, 168, 168, 0.5);
  overflow: hidden;
  margin: 16px -16px 0px -16px;
  padding: 10px 18px;
  background-color: #e5ebed;
}

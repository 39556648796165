/* Put 'common' styles in here which you want to be accessible anywhere in this module */

.defaultLabel {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #2c3439;
    margin-top: 24px;
    margin-bottom: 8px;
}

.defaultInput {
    height: 40px;
    padding: 8px 16px;
    font-size: 16px;
    border: 1px solid #b2c2c8;
    border-radius: 4px;
    width: 100%;
}

.defaultInputWithWarning {
    height: 40px;
    padding: 8px 16px;
    font-size: 16px;
    border: 1px solid #b2c2c8;
    border-radius: 4px;
    width: 100%;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    background-image :
            url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC80lEQVRIiZXVMWhddRQG8N+5ZAhFQqgxhA6lFJESioiDFVykaGwjFOxQCjdGFAcpiIQOUqQU6dDNbGIJFMR3U5cOWilCOhQHlVBEHELAhzhVCCkUCQ4S3nG4N6/Jy3tJPeOfc853znfO/3xhD+tUDkeaEt7A8UzjEQqsYwVL+B7tKPvniN6HrMBEcjk4J40JZD9v8Ajf4gr+7AUa2gXAFK7jSFPCJn5IloO/pEJ4JnkRrwaj0myGKWkOXw/sICszmRaEYWwEX2A+Sg/6lZ4tYxku4CPpYIR/cQmfbXUSjSPhpHQbB5J2hBL3o9TZQV8qYqbnjUlU0gtNx+9IizFD0cBMZFpIDght4XSUlnckbzmEm8ntTuWlLgUlUVpJ3hR+SYYyzWd4li0ALglHhY3k7aLU3sVHuJrpPKalhay6saCoaSzVGzYeXM0WRVYO4XwkwY1guR/fGIoggqiXo+jjsyrNRz3ZaeFYgdcwLnSk+e207LB0TbqX/CpcjNLmriZLInye/IMRnImsfCnNCj9G6ZUB1f8vy5ZbyVncHcJks6yDqAGdlmk8r6ZoLUo3BjqHnyKdFSaHMA7Jw70AIryF9zNJfmMwQPKomcNId1D9r8COIJldsD0tGr9M3cMl09P7IWxt0RbQQNcw2lT9d6G+inj8efqXVYPsk3urmBNNyEqBpSbo5U7VHLiBcbqdDPSpjOIUZFoqcDdYi1BIc70/dHv2/SjKiuSDCE9hI8J3RZQeZFpsen/PIKqejKLnpItNp3ewOgQRruEMjiZfdSqne+9RpFvC75FkWNtVfctEchNj0rrwSZQ628/1VPJNMIw2yuR+Meh0bKMFx1CpRWgzeTdSK2Z2C84srkvDGU8gOJWx5AI+xFikTeFjvYLTE3Qqa8k8HDXnm8I96efgoXrII8IJ6WSE4awv8RrmhMXturwLoFOr1qEIl3EOB/ek6LHofxr80Sv6Aze64fZIpqkIr+M4JrJe13VpNcMS7kRqx0z/PP8BSVYmmPbH6iEAAAAASUVORK5CYII=')

}

.defaultInput::placeholder {
    color: #969a9c;
}

.defaultInput:disabled {
    border-color: #d1dadf;
    background: #eff2f4;
    cursor: not-allowed;
}

.appContainer {
    min-height: 100vh;
}

.defaultTitle {
    color: "#655655";
}



.warningIcon{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC80lEQVRIiZXVMWhddRQG8N+5ZAhFQqgxhA6lFJESioiDFVykaGwjFOxQCjdGFAcpiIQOUqQU6dDNbGIJFMR3U5cOWilCOhQHlVBEHELAhzhVCCkUCQ4S3nG4N6/Jy3tJPeOfc853znfO/3xhD+tUDkeaEt7A8UzjEQqsYwVL+B7tKPvniN6HrMBEcjk4J40JZD9v8Ajf4gr+7AUa2gXAFK7jSFPCJn5IloO/pEJ4JnkRrwaj0myGKWkOXw/sICszmRaEYWwEX2A+Sg/6lZ4tYxku4CPpYIR/cQmfbXUSjSPhpHQbB5J2hBL3o9TZQV8qYqbnjUlU0gtNx+9IizFD0cBMZFpIDght4XSUlnckbzmEm8ntTuWlLgUlUVpJ3hR+SYYyzWd4li0ALglHhY3k7aLU3sVHuJrpPKalhay6saCoaSzVGzYeXM0WRVYO4XwkwY1guR/fGIoggqiXo+jjsyrNRz3ZaeFYgdcwLnSk+e207LB0TbqX/CpcjNLmriZLInye/IMRnImsfCnNCj9G6ZUB1f8vy5ZbyVncHcJks6yDqAGdlmk8r6ZoLUo3BjqHnyKdFSaHMA7Jw70AIryF9zNJfmMwQPKomcNId1D9r8COIJldsD0tGr9M3cMl09P7IWxt0RbQQNcw2lT9d6G+inj8efqXVYPsk3urmBNNyEqBpSbo5U7VHLiBcbqdDPSpjOIUZFoqcDdYi1BIc70/dHv2/SjKiuSDCE9hI8J3RZQeZFpsen/PIKqejKLnpItNp3ewOgQRruEMjiZfdSqne+9RpFvC75FkWNtVfctEchNj0rrwSZQ628/1VPJNMIw2yuR+Meh0bKMFx1CpRWgzeTdSK2Z2C84srkvDGU8gOJWx5AI+xFikTeFjvYLTE3Qqa8k8HDXnm8I96efgoXrII8IJ6WSE4awv8RrmhMXturwLoFOr1qEIl3EOB/ek6LHofxr80Sv6Aze64fZIpqkIr+M4JrJe13VpNcMS7kRqx0z/PP8BSVYmmPbH6iEAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    width: 25px;
    margin-right: 5px;
    display: inline-block;
}

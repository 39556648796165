.primary {
  transition: background, box-shadow 0.15s ease-in-out;
  border-color: #007eca;
  background-color: #007eca;
  color: #fff;
  padding: 8px 20px 8px 20px;
  border-radius: 4px;
  box-shadow: 0 5px 18px -10px rgba(44, 52, 57, 0.25),
    0 5px 18px -10px rgba(0, 120, 171, 0.3);
  display: flex;
  font-weight: 700;
  justify-content: center;
}

.primary:hover:not(:disabled) {
  box-shadow: 0 6px 6px -5px rgba(0, 120, 171, 0.3),
    0 6px 6px -5px rgba(0, 0, 0, 0.3);
}

.primary:disabled {
  cursor: not-allowed;
  opacity: 0.4;
  box-shadow: none;
}

.primary:disabled:active {
  border: none;
}

.primary.pendingStyle:disabled {
  opacity: 1;
}

.secondary {
  transition: background, box-shadow 0.15s ease-in-out;
  color: #003349;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  padding: 8px 12px;
  border: 1px solid #b2c2c8;
  background: transparent;
  display: flex;
  justify-content: center;
}

.secondary:hover {
  box-shadow: inset 0 1px 4px 0 #ccd6db;
  background-color: #f5f7f8;
}

.secondary:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.secondary:disabled:hover {
  box-shadow: none;
}

.danger {
  transition: background, box-shadow 0.15s ease-in-out;
  border-color: #dc3545;
  background-color: #dc3545;
  color: #fff;
  padding: 8px 20px 8px 20px;
  border-radius: 4px;
  box-shadow: 0 5px 18px -10px rgba(44, 52, 57, 0.25),
    0 5px 18px -10px rgba(0, 120, 171, 0.3);
  display: flex;
  font-weight: 700;
  justify-content: center;
}

.danger:hover:not(:disabled) {
  box-shadow: 0 6px 6px -5px rgba(0, 120, 171, 0.3),
    0 6px 6px -5px rgba(0, 0, 0, 0.3);
}

.borderless {
  border-color: transparent;
  background: transparent;
  color: #007eca;
}

.borderless:hover {
  text-decoration: underline;
}

.borderless:disabled {
  opacity: 0.4;
}

.borderless:disabled:hover {
  text-decoration: none;
}

.borderless.pendingStyle:disabled {
  opacity: 1;
}

.secondarySelected {
  box-shadow: inset 0 1px 4px 0 #ccd6db;
  background-color: #f5f7f8;
}

.floating {
  border: none;
  background-color: #0072b6;
  color: #fff;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 100%;
  box-shadow: 0 10px 20px -6px rgba(0, 51, 73, 0.15),
    0 10px 20px -6px rgba(44, 52, 57, 0.15);
}

.floating:disabled {
  cursor: not-allowed;
  background-color: #d7d7d9;
  color: #969a9c;
}

.contentWrapper {
  display: flex;
}

.icon {
  fill: currentColor;
  width: 20px;
  height: 20px;
}

.icon + *:not(:empty) {
  margin-left: 8px;
}

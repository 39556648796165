.default {
    background-color: #fcfcfc;
    border: none;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
    padding: 15px;
    overflow: hidden;
    background-color: #fcfcfc;
}

.row-label {
    margin: 10px 0px;
    line-height: 1.1;
    display: block;
    font-size: 18px;
}

.no-content {
    font-weight: unset;
    margin: 0;
    color: #777;
    text-align: center;
    padding: 40px 0px;
}

.defaultInfo h4 {
    font-weight: unset;
    margin: 0;
    color: #777;
}

.actionBarWrapper {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 2px 14px 0 rgba(168, 168, 168, 0.5);
    margin: 0 -16px;
    padding: 16px;
}

.actionBarWrapper p {
    margin-top: 10px;
    color: #655655;
}

.backToSearchLink {
    display: flex;
    line-height: initial;
    font-size: 21px;
}

.backToSearchLink svg {
    margin-right: 8px;
}

.goBackButton {
    background-color: white;
    border-color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.goBackButton > span > span {
    color: #007eca;
}

.goBackIcon {
    height: 20px;
    width: 20px;
    position: relative;
    right: 2px;
    top: 14px;
    fill: #007eca;
    cursor: pointer;
}
.cancelButton {
    background-color: white;
    border-color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.cancelButton > span > span {
    color: #007eca;
}

.actionButton {
    display: flex;
}

.actionBarButton {
    flex: 1 1 auto;
    border: 1px solid;
    text-align: center;
    margin: 5px;
}
.center {
    text-align: center;
    margin: 0 auto;
}
.actionButtonsContainer {
    display: flex;
}

.selectCluster:focus {
    border-color: #fdb415;
    outline: 0;
}

.clearSelectedIcon {
    height: 20px;
    width: 20px;
    position: relative;
    right: 0px;
    top: 5px;
    margin-left: 10px;
    cursor: pointer;
}

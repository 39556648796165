.horizontalInputWrapper {
    display: flex;
}

.horizontalInputWrapper > * {
    flex-grow: 1;
}

.horizontalInputWrapper > * {
    margin-right: 15px;
}

.descriptionField {
    min-height: 200px;
}

.activeStatusRow {
    max-width: 50%;
}
.topSection {
    padding: 16px 0;
}
.clusterFormBackground {
    background-color: #f5f7f8;
}
.clusterFormPadding {
    padding: 30px;
}
.actionBarWrapper {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 2px 14px 0 rgba(168, 168, 168, 0.5);
    margin: 0 -16px;
    padding: 16px;
}

.backToSearchLink {
    display: flex;
    line-height: initial;
    font-size: 21px;
}

.backToSearchLink svg {
    margin-right: 8px;
}

.goBackButton {
    background-color: white;
    border-color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.goBackButton > span > span {
    color: #007eca;
}

.goBackIcon {
    height: 16px;
    width: 16px;
    position: relative;
    right: 5px;
    top: 2px;
    fill: #007eca;
    cursor: pointer;
}
.cancelButton {
    background-color: white;
    border-color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.cancelButton > span > span {
    color: #007eca;
}

.actionButton {
    display: flex;
}

.actionBarButton {
    flex: 1 1 auto;
    border: 1px solid;
    text-align: center;
    margin: 5px;
}

.warning{
    width: 550px;
}

.topSection {
    padding: 16px 0;
}

.unitTotal {
    height: 32px;
    width: 159px;
    color: #2c3439;
    font-size: 21px;
    line-height: 32px;
}

.resultMessage h3 {
    font-size: 21px;
    line-height: 32px;
    color: #2c3439;
    font-weight: normal;
}

.actionMenuWrapper {
    float: left;
}

.actionBarWrapper {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 15px 16px 0 rgba(168, 168, 168, 0.5);
    margin: 0 -16px;
    padding: 16px;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backArrowAndState {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backToLink {
    display: flex;
    align-items: center;
    line-height: initial;
}

.headerWrapper a {
    color: #007eca;
}
.backToSearchLink {
    display: flex;
    line-height: initial;
    font-size: 21px;
    margin-left: auto;
}

.backToSearchLink svg {
    margin-right: 8px;
}
.actionBarButton {
    flex: 1 1 auto;
    border: 1px solid;
    text-align: center;
    margin: 5px;
}

.keyUnitLabel {
    color: #003349;
    font-size: 16px;
}

.removeUnitIcon {
    height: 16px;
    width: 16px;
    position: relative;
    right: 1px;
    top: -1px;
    fill: #007eca;
}
.removeUnitButton {
    background-color: white;
    border-color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.removeUnitButton > span > span {
    color: #007eca;
}
.tableContainer {
    margin-right: auto;
    margin-left: auto;
    padding: 0 43px;
}

.tableContainer input[type="checkbox"] {
    opacity: 0.2;
    pointer-events: none;
}

.loaderDots {
  display: block;
  margin: auto;
}

.loaderDots > div {
  background-color: #0078ab;
  opacity: 0.2;
  margin: 0 4px;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 0.8s infinite ease-in-out both;
  animation: sk-bouncedelay 0.8s infinite ease-in-out both;
}

.loaderDots .bounce1 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.loaderDots .bounce2 {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

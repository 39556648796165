p.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 14px 0 rgba(168, 168, 168, 0.5);
}

.backToLink {
  display: flex;
  align-items: center;
  line-height: initial;
}

.headerWrapper a {
  color: #007eca;
}
.backToSearchLink {
  display: flex;
  line-height: initial;
  font-size: 21px;
}

.backToSearchLink svg {
  margin-right: 8px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 14px 0 rgba(168, 168, 168, 0.5);
  overflow: hidden;
  margin: 16px -16px 0px -16px;
  padding: 10px 18px;
}

.backArrowIconStyleSettings{
  height: 16px;
  width: 16px;
  fill: currentColor;
}

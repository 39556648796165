.lomSelect {
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.lomSelect:focus {
    border-color: #fdb415;
    outline: 0;
}

.center {
    text-align: center;
    margin: 0 auto;
}

body {
    background-color: #e5ebed;
}

h4 {
    color: #655655;
}

.ReactTable .dropdown a {
    color: #333;
    text-decoration: none;
}

.ReactTable .dropdown a:hover,
.ReactTable .dropdown a:focus {
    color: #333;
    text-decoration: none;
    background-color: #e8e8e8;
}

.ReactTable a,
.defaultLink,
.defaultBackLink {
    color: #00a6bb;
    text-decoration: none;
}

.ReactTable a:hover,
.ReactTable a:focus,
.defaultLink:hover,
.defaultLink:focus {
    color: #007eca;
    text-decoration: none;
}

.ReactTable .rt-tbody .rt-td {
    white-space: unset !important;
}

.defaultBackLink:hover,
.defaultBackLink:focus {
    color: #007eca;
    text-decoration: none;
}

.rt-td {
    color: #655655;
}

.toast-success-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: #ffffff !important;
    border: 1px solid #34a853 !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
    overflow: hidden;
    position: relative;
}

.toast-success-container-after::after {
    top: 0;
    left: 0;
    content: "";
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: #34a853;
}

.toast-error-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: #ffffff !important;
    border: 1px solid #d9534f !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-error-container-after {
    overflow: hidden;
    position: relative;
}

.toast-error-container-after::after {
    top: 0;
    left: 0;
    content: "";
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: #d9534f;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 700px;
  transition: max-height 0.25s;
}

.searchCollapsed {
  max-height: 0;
  padding: 0;
}

.header {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.pageTitle {
  font-size: 21px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  max-width: 450px;
}

.formElementContainer {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  flex-grow: 1;
}

.formElementContainer + .formElementContainer {
  margin-left: 8px;
}

.formRow {
  display: flex;
  flex-direction: row;
}

.formRangeSeperatorText {
  font-size: 16px;
  padding: 8px;
  display: flex;
  align-self: center;
}

.zipInput {
  flex-basis: 15%;
}

.searchButton {
  margin-top: 56px;
}

.messageContainer{
  position: absolute;
  bottom: 0;
  padding-right: 15px;
}

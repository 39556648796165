.container {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: row;
}

.formContainer {
    display: flex;
    flex-direction: column;
    max-width: 450px;
}

.formElementContainer {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.nameAndUnitCodeRow {
    display: flex;
    flex-direction: row;
}

.nameAndUnitCodeRow > div {
    width: 100%;
}

.descriptionRow textarea {
    min-height: 230px;
}

.headerWrapper a {
    color: #007eca;
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 14px 0 rgba(168, 168, 168, 0.5);
    overflow: hidden;
    margin: 16px -34px 0px -34px;
    padding: 10px 18px;
}

.backLink {
    display: flex;
    align-items: center;
    line-height: initial;
    font-size: 21px;
}

.backLink svg {
    margin-right: 8px;
}

.menuActionsBtn {
  background: rgba(230, 230, 230, 0.5);
  border: none;
  font-size: 16px;
  padding: 0 8px;
  border-radius: 2px;
  height: 32px;
  width: 50px;
}

.menuActionsBtn:hover {
  background-color: rgba(230, 230, 230, 1);
}

.menuActionsBtn:before {
  content: '•••';
}

.menuActionsBtnDisabled {
  color: lightgrey;
  pointer-events: none;
  cursor: default;
}

.menuActionsBtnDisabled:hover {
  background-color: rgba(230, 230, 230, 0.5);
}

li.dropdownActions {
  display: -webkit-inline-box;
  cursor: pointer;
}
